import React, { useContext, useEffect } from 'react';
import { motion } from 'framer-motion';
import GlobalContext from '../context/GlobalContextProvider';

export default function PageTransition({ children }) {
  const [
    navNumber,
    setNavNumber,
    urlChecker,
    checkNavPosition,
    direction,
  ] = useContext(GlobalContext);

  const variants = {
    left: {
      x: '-100vw',
    },
    right: {
      x: '100vw',
    },
  };

  return (
    <motion.div
      variants={variants}
      initial={direction && direction === 'left' ? 'left' : 'right'}
      animate={{ x: 0 }}
      transition={{
        type: 'spring',
        restSpeed: 2,
        duration: 0.9,
      }}
    >
      {children}
    </motion.div>
  );
}
